/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// @import "./assets/sass/style";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
@import "./assets/sass/style.angular.scss";

.btn.btn-primary:active:not(.btn-active){
    background-color: #005ABE !important;
    font-size: 18px !important;
    color: #FFFFFF !important;
    // width: 300px !important;
    // height: 48px !important;
}

.btn.btn-primary:focus:not(.btn-active){
    background-color: #005ABE !important;
    font-size: 18px !important;
    color: #FFFFFF !important;
    // width: 300px !important;
    // height: 48px !important;

}
hr{
    border: 1px solid rgba(0,50,110,0.3) !important;

}

.btn.btn-primary:disabled{
    background-color: #cccccc !important;
    color: #999999 !important;
    font-weight: bold !important;
    font-size: 18px !important;
    // width: 300px !important;
    // height: 48px !important;
  }
.btn.btn-active-color-primary:hover:not(.btn-active){
  color: #004696 !important;
  font-size: 18px !important;
  // width: 300px !important;
  // height: 48px !important;

}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
    -webkit-animation: .2s ease-in borderscale;
    animation: .2s ease-in borderscale;
    background: #005ABE !important;
}

.multiselect-item-checkbox input[type=checkbox] + div:before {
    box-sizing: content-box;
    content: "";
    color: #005ABE !important;
    position: absolute;
    top: 50%;
    left: 0;
    width: 14px;
    height: 14px;
    margin-top: -9px;
    border: 2px solid #005ABE !important;
    text-align: center;
    transition: .4s;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #005ABE !important;;
    margin-right: 4px;
    margin-bottom: 4px;
    background: #005ABE !important;;
    padding: 0 5px;
    color: #fff;
    border-radius: 2px;
    float: left;
}

.svg-icon {
    line-height: 1;
    color: #005ABE;
}

.bg-dark {
    --kt-bg-rgb-color: var(--kt-dark-rgb);
    background-color: #005ABE !important;
}

.drag-drop label {
    color: #fff;
    width: 183px;
    height: 44px;
    border-radius: 21.5px;
    background-color: #005ABE !important;
    padding: 11px;
}

.form-border{
    padding: 15px;
      border: 2px solid #d8d1d1;
      border-radius: 10px;
  }

.btn.btn-primary{
    background: #005ABE !important;
    border: none !important;
    font-size: 18px !important;
    color: #FFFFFF !important;
    // width: 300px !important;
    // height: 48px !important;
}

.btn.btn-primary:hover:not(.btn-active){
    background: #004696 !important;
    border: none !important;
    font-size: 18px !important;
    color: #FFFFFF !important;
    // width: 300px !important;
    // height: 48px !important;
}

.page-link.active, .active > .page-link{
    background-color: #005ABE !important;
}

.modal-header{
    background: #005ABE;
    color: white;
}

.modal-title{
    color: white !important;
}

.dropdown-menu.show {
    display: block;
    transform: none !important;
}

.btn.btn-outline-primary.dropdown-toggle:after{
    color: #005ABE !important;
}

.card .card-header{
    align-items: center;
    background: #005ABE;
    color: white;
}

.form-check-input:checked {
    background-color: #005ABE;
    border-color: #005ABE;
    width: 300px !important;
    height: 48px !important;
}

.required{
  font-weight: bold;
}

 .hot-toast-message{
    font-size: 15px !important;
}

.form-control {
  height: 45px !important;
}

.form-select {
  box-shadow: none!important;
  height: 45px !important;
}

.form-control:disabled {
  height: 45px !important;
}

.table>thead {
  background-color: #005abe !important;
}

.table thead th {
  color: #ffff !important;
  font-size: 14px !important;
  font-weight: 800 !important;
}


.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
}

.header-fixed .header{
height: 80px !important;
}
